import React from 'react';
import './App.scss';
import Header from '../src/components/headers';
import Home from '../src/components/home';
import OurHistory from './components/ourhistory';
import Menu from '../src/components/menu';
import Contact from '../src/components/contact';
import Footer from '../src/components/footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.js";
import "bootstrap/dist/js/bootstrap.min.js";
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
    <Header />
    <ScrollToTop />
    <Switch>
        <Route path="/" exact component={Home}/>
        <Route path="/ourhistory/" component={OurHistory}/>
        <Route path="/menu/" component={Menu}/>
        <Route path="/contact/" component={Contact} />
    </Switch>
    <Footer />
</Router>
  );
}

export default App;
