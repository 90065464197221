import React from 'react';
import {Helmet} from 'react-helmet';
import HeroImage from '../images/heroimage.jpg';
import ImageEggs from '../images/Eggs45123.jpg';
import ImageBurger from '../images/Burger45144.jpg';
import ImageSurfTurf from '../images/SurfTurf45240.jpg';
import ImageOutdoor from '../images/outdoor.jpg';
import ImagePickUp from '../images/pickup.jpg';
import Header from '../components/headers';
import HeroBanner from '../components/hero-banner';
import {Link} from 'react-router-dom';

function Home({match}){
    return (
        <div className="home-wrapper">
        <Helmet>
            <title>Orem's Diner | Home Page</title>
            <meta name="description" content="Welcome to Orem's Diner, voted Nr1 in Southern Connecticut" />
        </Helmet>
            <HeroBanner bkg={HeroImage} title={"Welcome to Orem's Diner"} subtitle={"Where Wilton Happens!"} showOrderButton={true} />
            <section className="food-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>We're serving up your Orem's favorites!</h2>
                        </div>
                    </div>
                    <div className="row food-row">
                        <div className="col-md-4">
                        <Link to={{pathname: `/menu/`, aboutProps:{mainCat:'breakfast', secondCat:'Eggs'}}}>
                            <div className="food-overlay"></div>
                            <div className="food-bkg" style={{backgroundImage: `url(${ImageEggs})`}}></div>
                            <div className="food-text-wrapper">
                            <h3>Breakfast</h3>
                                <p>Nobody puts as much care and precision into each dish as we do!</p>
                            </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                        <Link to={{pathname: `/menu/`, aboutProps:{mainCat:'lunch', secondCat:'Salads'}}}>
                            <div className="food-overlay"></div>
                            <div className="food-bkg" style={{backgroundImage: `url(${ImageBurger})`}}></div>
                            <div className="food-text-wrapper">
                            <h3>Lunch</h3>
                                    <p>When the craving strikes, we're here to make sure you can still have the great meals you love.</p>
                            </div>
                            </Link>
                        </div>
                        <div className="col-md-4">
                        <Link to={{pathname: `/menu/`, aboutProps:{mainCat:'dinner', secondCat:'Poultry'}}}>
                            <div className="food-overlay"></div>
                            <div className="food-bkg" style={{backgroundImage: `url(${ImageSurfTurf})`}}></div>
                            <div className="food-text-wrapper">
                            <h3>Dinner</h3>
                                <p>We're firm believers that great ingredients make great food.</p>
                            </div>
                            </Link> 
                        </div>
                    </div>
                </div>
            </section>
            <section className="map-section no-padding-section">
                <div className="map-responsive">
                    <iframe title="orems-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12011.170991395027!2d-73.4183079!3d41.1826874!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe2558ff015811cf!2sOrem&#39;s%20Diner!5e0!3m2!1sen!2sus!4v1590360583252!5m2!1sen!2sus" width="600" height="450" aria-hidden="false" tabIndex="0"></iframe>
                </div>
            </section>
        </div>
    )
}

export default Home;