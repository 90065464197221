import React, {useEffect, useState} from 'react';
import Logo from '../../src/oremslogo.png';
import {Link} from 'react-router-dom';

function Header() {

    useEffect(()=>{
        let screenSize = window.innerWidth;
        setWindowSize(screenSize);
        let getMenuLinks = document.querySelectorAll('.navbar-nav a');
        setMenuLinks(getMenuLinks);
    }, []);

    let [windowSize, setWindowSize ] = useState();
    const [menuLinks, setMenuLinks ] = useState([]);

    function fixMenu(){
        document.querySelector('.navbar-toggler').click();
    }

    if (windowSize < 991) {
        menuLinks.forEach(function(button){
            button.addEventListener('click', fixMenu, true);
        });
    } 

    if (windowSize > 991) {
        menuLinks.forEach(function(button2){
            button2.removeEventListener('click', fixMenu, true);
        });
    }

    document.getElementsByTagName("body")[0].onresize = function() {
        setWindowSize(window.innerWidth);

        if (windowSize < 991) {
            menuLinks.forEach(function(button){
                button.addEventListener('click', fixMenu, true);
            });
        } 

        if (windowSize > 991) {
            menuLinks.forEach(function(button2){
                button2.removeEventListener('click', fixMenu, true);
            });
        }

    };

    return (
        <header>
            <div className="container-fluid top-menu">
            <div className="row">
                <div className="col-3">
                    <img className="img-fluid" src={Logo} alt="Orem's Logo" />
                </div>
                <div className="col-9">
                    <nav className="navbar navbar-expand-lg navbar-light">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                            <div className="navbar-nav">
                                <Link to={`/`}>Home</Link>| 
                                <Link to={`/ourhistory/`}>Our History</Link>| 
                                <Link to={`/menu/`}>Menu</Link>| 
                                <Link to={`/contact/`}>Contact</Link>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
    )
}

export default Header;