import React from 'react'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WhiteLogo from '../images/OremsLogo2.png';

function Footer(){
    return (
        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6 text-center">
                        <h3>Social Media</h3>
                        <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/OremsDiner/"><FontAwesomeIcon icon={faFacebook} /></a> | <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/oremsdiner/"><FontAwesomeIcon icon={faInstagram} /></a>
                    </div>
                    <div className="col-md-6 text-center">
                        <h3>Contact</h3>
                        <FontAwesomeIcon icon={faLocationArrow} /> 167 Danbury Road | Wilton, CT 06897<br />
                        <a href="mailto:info@oremsdiner.com"><FontAwesomeIcon icon={faMailBulk} /> info@oremsdiner.com</a><br />
                        <FontAwesomeIcon icon={faPhone} /> (203) 762-7370
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <img className="logo-bottom" src={WhiteLogo} alt="Orem's Logo" /><br />
                        Orem's Diner &copy; 2020
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;