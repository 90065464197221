import React from 'react';
import {Helmet} from 'react-helmet';
import OremsPhoto from '../images/history05.jpg';
import HeaderPhoto from '../images/history-background.jpg';
import HeroBanner from '../components/hero-banner';
import History04 from '../images/history04.jpg';
import History01 from '../images/history01.jpg';
import History02 from '../images/history02.jpg';
import Comicstrip from '../images/history07.jpg';

function OurHistory() {
    return(
        <div className="our-history-wrapper">
            <Helmet>
                <title>Orem's Diner | Our History</title>
                <meta name="description" content="Discover Orem's Diner's history." />
            </Helmet>
            <HeroBanner bkg={HeaderPhoto} title={'History'} subtitle={'A Brief History of Orem’s Diner'} showOrderButton={false} />
            <section className="history-intro">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Charles H. Orem</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <img src={OremsPhoto} alt="Charles H. Orem" />
                        </div>
                        <div className="col-md-9">
                            <p>Charles H. Orem was born in Ohio in 1882. He worked as a repairman for National Cash Register. Around 1910 he opened a confectionery store in New Jersey. Approximately ten years later, in 1920, Mr. Orem came to Wilton, where he bought a house at 213 Danbury Road with 26 acres of land. He started a dairy farm with one cow, and opened a small ice cream stand. Behind his store, he built a baseball field complete with a covered grandstand. His growing dairy herd, consigned to the acreage behind the ballpark, occasionally ventured onto the playing field, leaving slippery evidence of their visits.</p>

                            <p>In addition to baseball, Mr. Orem encouraged the use of his field for community events as it brought good business to his store. A carnival at Orem’s Field was organized in June of 1920 by Grace Schenck to raise funds for a district nurse. It featured a parade of autos, a baby show, vaudeville, music, dancing, and general fun for the times.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">
                            <p>Orem’s Diner got its start in April 1921 when Mr. Orem added a deli to his ice cream business. At the end of the 1921 school year a field day was held at the ball field. About 500 students and parents attended. Mr. Orem gave every child a dish of ice cream, a bag of peanuts, and orangeade. He provided a dance floor and orchestra and gave door prizes.</p>
                            <p>Later that summer, the 4th of July fireworks were held at the field with hundreds in attendance. Perhaps a record crowd, estimated at 900, showed up on Labor Day at the first annual carnival of the Wilton Athletic Club. The total population of Wilton at the time was less than 1,800.</p>
                        </div>
                        <div className="col-md-3">
                            <img className="img-fluid" src={History04} alt="orem's history" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <p>Later that summer, the 4th of July fireworks were held at the field with hundreds in attendance. Perhaps a record crowd, estimated at 900, showed up on Labor Day at the first annual carnival of the Wilton Athletic Club. The total population of Wilton at the time was less than 1,800.</p>

                            <p>Many baseball teams and famous players played on Orem’s Field, including one George Herman Ruth. The first Fireman’s Field Day was held there in 1931. Three years later, The Wilton Firemen lineup included:</p>

                            <p>Vasale – 3B<br />
                            Scofield – 1B<br />
                            Pleasants – 2B<br />
                            Banks – C<br />
                            R. Edge – SS<br />
                            Johnson – RF<br />
                            G.Lindstrom – LF<br />
                            Iffland – CF<br />
                            Lefty Cole – P</p>
                            <p>The better-known Wilton Farmers team continued at Orem’s Field until World War II when play was suspended.</p>

                            <p>Professor Christopher Collier, a former Connecticut State Historian who grew up in Wilton in the 30s and 40s, remembered many baseball games at Orem’s Field:</p>

                            <p><em>“The Farmers played on Sunday afternoons. And if they hit a ball out of the field the kids would rush to try to get the ball because if you turned it in they gave you a nickel. And for a nickel you could get an ice cream cone or a Hershey bar. And I got a ball once and got my nickel and went off to Orem’s with about six kids following me hoping to get a cut in on the treat.”</em></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3">
                            <img className="img-fluid" src={History01} alt="old orem's" />
                        </div>
                        <div className="col-md-9">
                            <p>After a few years Mr. Orem gave up management of the diner in order to concentrate on the dairy business. When in 1939 he sold Orem’s Dairy and the dairy herd to Richard Hoyt, it numbered 165 cows and his milk routes covered Wilton and several neighboring towns. During World War II, with shortages of gasoline and tires, Hoyt traded customers with other dairies to cut back his mileage and consolidate his routes within Wilton. Hoyt later sold his herd and bought raw milk from other farmers to process for his customers. In 1968 Richard Hoyt Jr. closed Orem’s Dairy.</p>

                            <p>Lyle and Florence Tallmadge leased the diner from Mr. Orem in November 1941 and ran it during the war years. Florence, who was also Linda Tallmadge Mitchell’s mother (of Mitchell’s of Westport), still lived in Wilton until her death in 2015. In an interview in 2000, she remembered:</p>

                            <p><em>“We leased the diner in 1941 and I remember we had just bought the diner when they declared the war. I remembered so distinctly because we were driving to Danbury, we had the radio on and we heard the news on the radio about the war being declared. We wondered how it would affect the lease that we had signed. But it worked out ok. We were able to get meat through ration stamps. They allowed a certain amount for the diner. Actually a lot of people liked eating out, they would get extra meat that way, more than eating at home. So it really didn’t hurt the business at all. We had a five year lease and at the end we were exhausted, through, finished.”</em></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">
                            <p>The covered baseball stands were taken down by Mr. Orem as a safety precaution in 1942, but the bleachers remained. In the same year Mr. Orem developed Orem’s Lane with several rental cottages and a new house for himself overlooking the baseball field. Young’s Nursery began leasing land from Mr. Orem in 1941. After the war, the Wilton baseball field moved up to the Harbs property near today’s Cider Mill School. Young’s Nursery bought the land after Mr. Orem died in 1973.</p>

                            <p>The kitchen and sections of the dining area were swept by fire on July 4, 1970. The kitchen was gutted and the dining area was heavily damaged by smoke and water. George Peters and Karl Dolnier led the Fire Department’s response. Sixteen men in total reported to the blaze. It was brought under control in about 15 minutes. Damage was estimated at $20,000. It was anticipated that the diner would remain closed for 60 days.</p>

                            <p>The current owners, the Papanikolaou family, have owned Orem’s Diner since 1980.</p>

                            <p><em>Compiled by Town Historian Bob Russell<br />
                            December 6, 2012<br />
                            Edited by Demetris Papanikolaou<br />
                            February 1, 2016</em></p>
                        </div>
                        <div className="col-md-3">
                            <img className="img-fluid" src={History02} alt="old orem's" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>A Brief Memoir of Orem’s</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                        <p>Orem’s Diner was established in 1921 by Charles Orem. Its colorful history (remember the pink paint) has been as interesting as the people who came to eat, meet their friends and close business deals in one of the booths.</p>

                        <p>The ’55 flood cemented us all together – the wealthy, the financially stressed, white and blue collar workers and even the hoboes who came to the back door for a free hot meal. Clyde Porter Shannon arrived in his Rolls Royce daily, Mr. Checkester, a prominent town lawyer, Mr. and Mrs. Bramwell rubbing elbows with Ellie Haye, carpenter and Yank Stannard Sr., plumber extraordinaire.</p>

                        <p>Who can forget Ed Cross who peeled hundreds of pounds of vegetables in the kitchen and Dick Hoyt, who owned the dairy next door? Dot Jinks, self-appointed town matriarch, and Bill O’Brien who came for 3 meals a day with the only dog allowed in the diner lying at his feet.</p>

                        <p>Bill Quaintance was there. He was our resident state trooper, and it was right at the counter where our future paid police department was farmed. Our earliest policemen were Bill, Max Haber, Joe Vasale, Dick Barringer, Jay Corsa, John Gochel and Steve Bartek Sr.</p>

                        <p>The volunteer fire department soon was developed from the seed of an idea over many cups of coffee.</p>

                        <p>Each year there were two major happenings that involved the diner. The Danbury Fair, which ran each fall; patrons lined up outside waiting to be seated. The diner was one of very few places to eat along Rt. 7. Old timers’ day rolled back the prices to the 1930’s. Owners Bob and Bud Iliff dressed in vintage clothing, as did the staff and many of the customers. It usually started officially when the Bramwells arrived in one of the antique cars they owned.</p>

                        <p>The waitresses, a loyal group, who worked there for years included Regis Wannamaker, sister to Bob and Bud, Tina Toofer – a niece of Charlie Orem, Lie Cade, Frieda Andreason and Ann Myclinsh. The four daughters of Bud Iliff, Sharon, Patty, Carlyn and Verna Lea helped out too.</p>

                        <p>When the Iliff brothers sold to the DeBernadino brothers in 1965 another new and exciting era started for Orem’s Diner and continues on.</p>

                        <p><em>Written by Mrs. Lillian Iliff</em></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <img src={Comicstrip} className="img-fluid" alt="orem's comic strip" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OurHistory;