import React from 'react';
import { Helmet } from 'react-helmet';
import ContactBackground from '../images/contact-background.JPG';
import HeroBanner from './hero-banner';


function Contact() {
    return (
        <div className="contact-wrapper">
            <Helmet>
                <title>Orem's Diner | Contact Us</title>
                <meta name="description" content="Find us on our social media pages, plus contact details" />
            </Helmet>
            <HeroBanner bkg={ContactBackground} title={'Contact Us'} subtitle={"Bring your favorite comfort food into the comfort of your own home. Order takeout or delivery with us today!"} />
            <section className="contact-hours">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>Orem’s Diner Hours</h2>
                            <p>Monday - Sunday: 6:00am - 10:00pm</p>
                            <p>Serving breakfast all day!</p>

                        </div>
                    </div>
                </div>
            </section>
            <section className="address-section colored-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2>How to Contact Us</h2>
                            <p>167 Danbury Road<br />
                            Wilton, CT 06897<br />
                            (203) 762-7370</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact;