import React from 'react';
import {Helmet} from 'react-helmet';
import MenuBackground from '../images/menu-background.jpg';
import HeroBanner from './hero-banner';
import Makebuttons from './Makebuttons2';
import PriceList from '../images/OremsLimitedMenu12-21.pdf'

function Menu(props) {

    let getMainCategory;
    let getSecondCategory;

    if (props.location.aboutProps !== undefined) {
        getMainCategory = props.location.aboutProps.mainCat;
        getSecondCategory = props.location.aboutProps.secondCat;
    } else {
        getMainCategory = 'breakfast';
        getSecondCategory = 'Eggs';
    }

    return(
        <div className="menu-wrapper">
            <Helmet>
                <title>Orem's Diner | Menu</title>
                <meta name="description" content="If you craving for a delicious lunch or dinner then you are in the right place" />
            </Helmet>
            <HeroBanner bkg={MenuBackground} title={'Menu'} subtitle={"There's nothing we value more than a homemade meal."} showOrderButton={true} />
           <div className="container">
               <div className='row'>
                   <div className='col-md-12'>
                   <p>Please <a target="_blank" href={PriceList}>click here</a> to view our menu</p>
                   </div>
               </div>
           </div>
        </div>
    )
}

export default Menu;