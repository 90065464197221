import React, {useState, useEffect} from 'react';
import * as MenuJson from '../menu.json';
import uuid from 'react-uuid';

function Makebuttons(props) {

    useEffect(() => {
        
      }, []);

    const [activeMainButton, setActiveMainButton] = useState(props.mainCat);

    const [activeSecondaryButton, setactiveSecondaryButton] = useState(props.secondCat);

    let mainCat = MenuJson.default;

    function createButtonsOnClick(event) {
        let getId = event.target.id;
        setActiveMainButton(getId);
    }

    function changeSubCategory(event) {
        let subCategory = event.target.getAttribute('data-item');
        setactiveSecondaryButton(subCategory);
    }

    let listOfSecButtons = [];

    let oremsDish = [];

    mainCat[activeMainButton].map(function(item){
        listOfSecButtons.push(item.dishCategory);
        if( item.dishCategory == activeSecondaryButton) {
            oremsDish.push(item);
        }
        if (item.dishCategory === '') {
            oremsDish.push(item);
        }
    });

    listOfSecButtons = [...new Set(listOfSecButtons)];

    return (
        <section className="menu-navigation">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <div className={`main-menu-item ${activeMainButton == 'breakfast' ? "main-menu-active-item" : ""}`} id="breakfast" onClick={createButtonsOnClick}>Breakfast</div>
                        <div className={`main-menu-item ${activeMainButton == 'lunch' ? "main-menu-active-item" : ""}`} id="lunch" onClick={createButtonsOnClick}>Lunch</div>
                        <div className={`main-menu-item ${activeMainButton == 'dinner' ? "main-menu-active-item" : ""}`} id="dinner" onClick={createButtonsOnClick}>Dinner</div>
                        <div className={`main-menu-item ${activeMainButton == 'sideorders' ? "main-menu-active-item" : ""}`} id="sideorders" onClick={createButtonsOnClick}>Side Orders</div>
                        <div className={`main-menu-item ${activeMainButton == 'beverages' ? "main-menu-active-item" : ""}`} id="beverages" onClick={createButtonsOnClick}>Beverages</div>
                        <div className={`main-menu-item ${activeMainButton == 'appetizers' ? "main-menu-active-item" : ""}`} id="appetizers" onClick={createButtonsOnClick}>Appetizers</div>
                        <div className={`main-menu-item ${activeMainButton == 'kidsmenu' ? "main-menu-active-item" : ""}`} id="kidsmenu" onClick={createButtonsOnClick}>Kids' Menu</div>
                        <div className={`main-menu-item ${activeMainButton == 'desserts' ? "main-menu-active-item" : ""}`} id="desserts" onClick={createButtonsOnClick}>Desserts</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div id="sub-categories">
                        {listOfSecButtons.map(function(subButton, index){
                            return (<button key={index} className="subCat-item" data-item={subButton} onClick={changeSubCategory}>{subButton}</button>)
                        })}
                        </div>
                        
                        
                        <div id="dishes" className="row">
                        {oremsDish.map(function(dish){
                            return (<div className="dish-item col-md-3" key={uuid()} >
                                <div className="item-wrapper">
                                    <div className="dish-name">{dish.dishTitle}</div>
                                    <div className="dish-details">{dish.dishDetails}</div>
                                    {dish.dishPrice !== '' && <div className="dish-price">${dish.dishPrice.toFixed(2)}</div>}
                                </div>
                            </div>)
                        })}
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}

export default Makebuttons;

