import React from 'react';



function Herobanner(props){

    return(
        <div className="subheader-wrapper">
            <div className="container-fluid hero-wrapper">
                <div className="row">
                    <div className="col-md-12">
                        <div className="hero-image" style={{backgroundImage: `url(${props.bkg})`}}>
                        <div className="hero-text">
                            <h1>{props.title}</h1>
                            <p>- {props.subtitle} -</p>
                            {props.showOrderButton ? (<a target="_blank" className="btn btn-order" href="https://direct.chownow.com/order/25657/locations/37940">ORDER ONLINE</a>) : ''}
                        </div>
                        <div className="hero-image-wrapper"></div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
    )
}

export default Herobanner;